import { ViewEncapsulation } from '@angular/core';
import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute ,Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})

export class LeftMenuComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  stepLabelSide: any;
  lastUrlSegment: string | any;
  stepLabel: any;
  tips = true;
  showIdea = false;
  oppActive = true;
  progressUpdate: any; 
  @ViewChild('stepper', { static: false }) stepper: MatStepper | any;
  stepName = localStorage.getItem('stepLabel');
  opportunityDetails: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private ps: ProgramService,private ngZone: NgZone,
    private changeref: ChangeDetectorRef
  ) {
    this.route?.params.subscribe((res: any) => {
      this.opportunityId = res.id ?atob(res.id): res.id;
    })
    this.dataService.stepLab.subscribe(data => {
      this.stepLabelSide = data;
    });
  }

  ngOnInit() {
    if (this.stepper != undefined) {
      this.setStepperData();
    }

    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.opportunityId = res.id ?atob(res.id): res.id;
    })
   
  }
  ngAfterViewInit() {
    this.setStepperData(); 
     this.ps.getValue().subscribe((newValue) => {
      setTimeout(() => {
        this.stepper.selectedIndex = newValue; 
        this.stepper._selectedIndex = newValue;
      }, 1000);
    });    
      

  }

  ngOnchange() {
    this.route.params.subscribe((res: any) => {
      this.opportunityId = res.id ?atob(res.id): res.id;
    })
  }

  setStepperData() {
    
    this.lastUrlSegment = this.router.url.split('?')[0].split('/').pop();
    const id = this.router.url.split('?')[0].split('/');
    this.getOpportunityById(id[2]);

    if (this.lastUrlSegment == 'scope') {
      if (this.stepper._selectedIndex == 0 || this.stepper._selectedIndex > 0) {
        this.stepper._selectedIndex = 1;
        this.progressUpdate = 40;
      }

    }
    else if (this.lastUrlSegment == 'setup') {
      if (this.stepper._selectedIndex == 0 || this.stepper._selectedIndex > 0) {
        this.stepper._selectedIndex = 0;
        this.progressUpdate = 20;
      }

    }
    else if (this.lastUrlSegment == 'evaluation') {
      if (this.stepper._selectedIndex == 0 || this.stepper._selectedIndex > 0) {
        this.stepper._selectedIndex = 2;
        this.progressUpdate = 50;
      }

    }
    else if (this.lastUrlSegment == 'terms') {
      if (this.stepper._selectedIndex == 0 || this.stepper._selectedIndex > 0) {
        this.stepper._selectedIndex = 3;
        this.progressUpdate = 70;
      }

    }
    else if (this.lastUrlSegment == 'more-info') {
      if (this.stepper._selectedIndex == 0 || this.stepper._selectedIndex > 0) {
        this.stepper._selectedIndex = 4;
        this.progressUpdate = 90;
      }

    }
    else if (this.lastUrlSegment == 'preview') {
      if (this.stepper._selectedIndex == 0 || this.stepper._selectedIndex > 0) {
        this.stepper._selectedIndex = 5;
        this.progressUpdate = 100;
      }
    }
    /*below detection helps to get data updated with help detection and helps to get rid of error Expression has changed after it was checked*/
    this.changeref.detectChanges();
  }


  getOpportunityById(id: any) {
    this.ps.getSingleOpportunityById(id).subscribe((res:any)=>{
      this.opportunityDetails = this.ps.opportunityDetails;
    })
  }

  getOpportunityId() {
    this.route.params.subscribe((res: any) => {
      this.opportunityId = res.id ?atob(res.id): res.id;
    })
  }

  changeStepper(e: any) {
    let stepLabel = e.selectedStep.label;
    this.getOpportunityId();

    if (stepLabel == this.staticText?.opportunity?.title) {
      this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/setup`]);
      this.stepper._selectedIndex = 1;
      this.progressUpdate = 20;
    }
    if (stepLabel == this.staticText?.opportunity?.scope || this.stepLabelSide == this.staticText?.opportunity?.scope) {
      this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/scope`]);
      this.progressUpdate = 40;
    }
    if (stepLabel == this.staticText?.rfx?.eval_matrix) {
      this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/evaluation`]);
      this.progressUpdate = 50;
    }
    if (stepLabel == this.staticText?.rfx?.more_info) {
      this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/more-info`]);
      this.progressUpdate = 90;
    }
    if (stepLabel == this.staticText?.toe.toe_shortform) {
      this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/terms`]);
      this.progressUpdate = 70;
      this.stepper._selectedIndex = 4;
    }
    if (stepLabel == this.staticText?.rfx?.preview) {
      this.router.navigate([`rfx/`+btoa(this.opportunityId)+`/opportunity/preview`]);
      this.stepper._selectedIndex = 5;
      this.progressUpdate = 100;
    }
    /*below detection helps to get data updated with help detection and helps to get rid of error Expression has changed after it was checked*/
    this.changeref.detectChanges();
  }

  showTip() {
  }
}
