<div class="row">
    <div class="col-md-12 ">
        <mat-card class="mt-4 matcorner" style="border:medium none;">
            <div class="img-container d-flex align-items-center">
                <!-- <img class="img-align" src="../../../../../assets/images/bg3.png"> -->
                <div class="fw-500 fs-18 py-2 w-100">{{opportunityDetails?.opportunity_name}}</div>
            </div>
            <div class="d-flex justify-content-center">
                <circle-progress [percent]="progressUpdate" [radius]="50" [innerStrokeWidth]="8"
                    [innerStrokeColor]="'#E1E1E8'" [outerStrokeColor]="'#232459'" [animation]="true"
                    [animationDuration]="300"></circle-progress>
            </div>
            <p class="d-flex justify-content-center fw-normal">{{staticText?.opportunity?.process_saved}}</p>
            <hr>
            <div class="stepper d-flex flex-column ml-2" id="setBox">
                <div class="mb-1">
                    <mat-stepper orientation="vertical" [selectedIndex]="this.stepper.selectedIndex" id="pad" linear #stepper class="left-menu cursor-pointer"
                        (selectionChange)="changeStepper($event)">
                        <ng-template matStepperIcon="edit">
                            <mat-icon class="cursor-pointer">circle</mat-icon>
                        </ng-template>
                        <mat-step label="{{staticText?.opportunity?.title}}" >
                            <ng-template matStepLabel >
                                <p class="pt-3 fs-16 fw-500 text-truncate  cursor-pointer">{{staticText?.opportunity?.title}}</p>
                            </ng-template>
                        </mat-step>
                        <mat-step label="{{staticText?.opportunity?.scope}}">
                            <ng-template matStepLabel>
                                <p class="pt-3 fs-16 fw-500 text-truncate  cursor-pointer">{{staticText?.opportunity?.scope}}</p>
                            </ng-template>
                        </mat-step>
                        
                        <mat-step label="{{staticText?.toe.toe_shortform}}">
                            <ng-template matStepLabel>
                                <p class="pt-3 fw-500 fs-16 text-truncate  cursor-pointer">{{staticText?.toe.toe_shortform}}</p>
                            </ng-template>
                        </mat-step>
                        <mat-step label="{{staticText?.rfx?.eval_matrix}}">
                            <ng-template matStepLabel>
                                <p class="pt-3 fw-500 fs-16 text-truncate  cursor-pointer">{{staticText?.rfx?.eval_matrix}}</p>
                            </ng-template>
                        </mat-step>
                        <mat-step label="{{staticText?.rfx?.more_info}}">
                            <ng-template matStepLabel>
                                <p class="pt-3 fw-500 fs-16 text-truncate  cursor-pointer">{{staticText?.rfx?.more_info}}</p>
                            </ng-template>
                        </mat-step>
                        <mat-step label="{{staticText?.rfx?.preview}}">
                            <ng-template matStepLabel>
                                <p class="pt-3 fw-500 fs-16 text-truncate  cursor-pointer">{{staticText?.rfx?.preview_send}}</p>
                            </ng-template>
                        </mat-step>
                    </mat-stepper>
                </div>
            </div>
        </mat-card>
        <div class="pt-4">
            <mat-card class="mat-size pt-2 cursor-pointer" *ngIf="showIdea" (click)="tips = true; showIdea = false">
                <img style="height: 24px; position: relative;
                width: 51px; right: 13px; bottom: -2px;" src="./assets/images/lightbulb 1.svg">
            </mat-card>
        </div>
        <!-- <div *ngIf="tips" style="width: 100%;">
            <app-tips type="Tips" tipType="setupTip" (closetips)="tips = false; showIdea = true">
            </app-tips>
        </div> -->
    </div>
</div>