import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RfxRoutingModule } from './rfx-routing.module';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatButtonModule } from '@angular/material/button';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
  declarations: [
    IndexComponent,
    LeftMenuComponent
  ],
  imports: [
    CommonModule,
    RfxRoutingModule,
    SharedModule,
    NgxPermissionsModule,
    MatButtonModule,
    MatStepperModule,
    MatIconModule,
    MatCardModule,
    NgCircleProgressModule.forRoot({
    }),
    
  ]
})
export class RfxModule { }
