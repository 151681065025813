import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { ProgramService } from 'src/app/shared/services/program.service';

const routes: Routes = [
  {
  path: '',data: { breadcrumb: null } ,
  loadChildren: () => import('../../pages/kepler/kepler.module').then(m => m.KeplerModule)
},
  {
    path: ':id',
    component: IndexComponent,
    data: { breadcrumb: (data: any) => `${data?.opportunity?.opportunity_name}`},
    resolve: { opportunity: ProgramService },
    children: [
      {
        path: '',
        redirectTo: 'opportunity',
        pathMatch: 'full'
      },
      {
        path: 'opportunity',
        loadChildren: () => import('./opportunity/opportunity.module').then(m => m.OpportunityModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RfxRoutingModule { }
