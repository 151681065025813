<div class="w-100 bg-lavendar mt-80" [ngClass]="{'mt-80': !iframeEmbedded}">
    <app-header></app-header>
</div>
<div class="p-3">
    <div class="px-0 pb-4" [ngClass]="{'pb-4': !iframeEmbedded}">
        <app-bread-crumb></app-bread-crumb>
    </div>
    <div class="row">
        <div class="col-sm-2">
            <div class="single-left-pane" [ngStyle]="{'height': !iframeEmbedded ? 'inherit' : '100%'}">
                <app-left-side-menu></app-left-side-menu>
            </div>
        </div>
        <div class="col-sm-7" [ngClass]="{'col-sm-10': iframeEmbedded, 'col-sm-7': !iframeEmbedded}">
            <div class="single-center-pane" [ngStyle]="{'height': !iframeEmbedded ? 'inherit' : '95vh'}">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="col-sm-3"  *ngIf="!iframeEmbedded">
            <div class="single-right-pane">
                <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
                <app-tags-mapped-projects></app-tags-mapped-projects>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>